body.light-theme {
  background-color: #ffffff;
  color: #000000;

  input {
    color: #000000 !important;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    -webkit-text-fill-color: #000000 !important;
    transition: background-color 0s ease-in-out, color 0s ease-in-out; 
  }
}

body.dark-theme {
  background-color: #0e121b;
  color: #ffffff;

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #2f3237 inset !important;
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 0s ease-in-out, color 0s ease-in-out; 
  }

  .modal-content {
    background-color: #2f3237 !important;
    color: #fff !important;
  }

  ::-webkit-input-placeholder {
    color: #717784 !important;
  }

  .ReactFlagsSelect-module_selectBtn__19wW7
    .ReactFlagsSelect-module_selectValue__152eS {
    color: #717784 !important;
  }

  .ReactFlagsSelect-module_selectValue__152eS
    .ReactFlagsSelect-module_label__27pw9 {
    color: #ffff !important;
  }

  .ReactFlagsSelect-module_selectBtn__19wW7 {
    background-color: #2f3237 !important;
    border-color: #676c78 !important ;
  }
  .ReactFlagsSelect-module_filterBox__3m8EU {
    ::-webkit-input-placeholder {
      color: #000000 !important;
    }
  }
  .ReactFlagsSelect-module_selectOptions__3LNBJ {
    color: #000000 !important;
  }
  .pill-input {
    background-color: #2f3237 !important;
    color: #ffffff !important;
    border-color: #676c78 !important ;
  }
  input {
    color: #ffffff !important;
  }
  .modal-body {
    color: #ffffff;
  }
  .close {
    color: #ffffff;
    background-color: #717784 !important;
    border-radius: 50%;
    width: 24px;
  }

  .submitModalbtn {
    color: #ffffff;
  }

  .followStyle {
    color: #ffffff;
  }
  .socialIcons {
    color: #ffffff !important;
  }

  .modalText {
    color: #bec0c6;
  }
  .submit-btn {
    background-color: #f86833 !important;
  }
  .oct-btn {
    background-color: #501600 !important;
  }
}

html,
body {
  overflow-x: hidden;
}

.svg-image {
  max-width: 100% !important;
  height: auto !important;
}

.container {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.welcome-style {
  font-family: "Noto Sans", sans-serif;
}

input {
  background: transparent !important;
}

.theme-toggle-btn {
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.theme-toggle-btn:hover {
  background-color: none;
}

.navbar-nav {
  flex-direction: row !important;
}

.btn-no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.oct-btn {
  font-size: 0.875rem !important;
  padding: 8px !important;
  background-color: #fef8e9 !important;
  text-align: center;
}

.submit-btn {
  background-color: #f64200 !important;
  color: #ffffff !important;
  height: 40;
}

.input-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}

.modal-dialog {
  max-width: 414px !important;
  margin: auto !important;
}

.modal.fade .modal-dialog {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.modal-header {
  border-bottom: 0 none !important;
}

.modal-content {
  overflow: hidden;
  text-align: center;
}

.modal-footer {
  border-top: 0 none !important;
  margin: auto;
}

.modal-title {
  margin: auto;
}

.modalText {
  color: #676c78;
  font-size: 0.875rem;
  font-weight: 400;
}

.followStyle {
  color: #0e121b;
  text-align: center;
  margin: auto;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 500 !important;
}

.socialIcons {
  margin: 0 8px;
  text-decoration: none;
  transition: color 0.3s;
  display: inline-block;
}

.submitModalbtn {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
}
.submitModalbtn .copyStyle {
  margin-left: 120px !important;
}
.pill-input {
  max-width: 400px !important;
  border-radius: 50px !important;
  border: 1px solid #ced4da !important;
}

.name-pill-input {
  max-width: 200px !important;
  border-radius: 50px !important;
  border: 1px solid #ced4da !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  width: 400px !important;
  border-radius: 50px !important;
  height: 40px;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding: 0 !important;
  //justify-content: center !important;
  //align-items: center !important;
}

// css for linear gradient from here: https://dev.to/afif/border-with-gradient-and-radius-387f

.survey-btn {
  position: relative;
  --bs-btn-border-color: none !important;
}
.survey-btn::before {
  content: "";
  position: absolute;
  border: 1px solid transparent !important;
  inset: 0;
  border-radius: 50px;
  background: linear-gradient(45deg, #fea235, #6da544);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.infoCard {
  margin: auto !important;
}

.card {
  border-radius: 16px !important;
}

// .card-text {
//   color: #676c78 !important;
// }

input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none !important;
}

.shortInput {
  .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    margin-left: 6em !important;
  }
}

.shortInput {
  .ReactFlagsSelect-module_selectBtn__19wW7:after {
    margin-left: 6em !important;
  }
}

.shortInput {
  .ReactFlagsSelect-module_label__27pw9 {
    margin-left: 50px !important;
  }
}
.navbarBorder {
  border-bottom: 1px solid;
  padding: 0 150px !important;
}

@media (max-width: 600px) {
  h3 {
    font-size: 1.2rem !important; /* Smaller size for mobile */
  }
}

@media only screen and (max-width: 375px) {
  .card {
    height: 40vh !important;
    width: 40vh !important;
  }
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    width: 350px !important;
    border-radius: 50px !important;
    height: 40px;
  }
}
@media only screen and (max-width: 400px) {
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    width: 350px !important;
    border-radius: 50px !important;
    height: 40px;
  }
}

@media (max-width: 440px) {
  .name-pill-input {
    max-width: 95% !important;
  }

  .pill-input {
    max-width: 290px !important;
    border-radius: 50px !important;
    border: 1px solid #ced4da !important;
  }

  .ReactFlagsSelect-module_selectBtn__19wW7 {
    max-width: 290px !important;
  }

  h2 {
    font-size: 1.25rem !important;
  }

  .shortInput {
    .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
      margin-left: 1.2em !important;
    }
  }

  .shortInput {
    .ReactFlagsSelect-module_selectBtn__19wW7:after {
      margin-left: 1.2em !important;
    }
  }

  .shortInput {
    .ReactFlagsSelect-module_label__27pw9 {
      margin-left: 20px !important;
    }
  }

  .mediumInput {
    .ReactFlagsSelect-module_label__27pw9,
    .ReactFlagsSelect-module_secondaryLabel__37t1D {
      font-size: 0.7em;
    }
  }

  .longInput {
    .ReactFlagsSelect-module_label__27pw9,
    .ReactFlagsSelect-module_secondaryLabel__37t1D {
      font-size: 0.6em;
    }
  }
}
