/* App.css */
@media (max-width: 414px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


.App {
  text-align: center;
  font-family: 'Poppins';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


:root {
  --font-family-main: 'Poppins', sans-serif !important;
}

body {
  font-family: var(--font-family-main, 'Poppins');
}

h2{
  font-weight: 500;
}